import { Api } from '@monorepo/shared/apiClient';
import {
  ViewCacheEvents,
  viewCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/viewCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';

type KeyParams = Api.FetchProjectViewsParams;
const KEY_START = 'projectViews';

const createQueryKey = ({ organizationId, projectId }: KeyParams) =>
  [KEY_START, organizationId, projectId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchProjectViews
>;

type UseProjectViewsParams = Partial<Api.FetchProjectViewsParams> & {
  config?: QueryConfig<Api.FetchProjectViewsResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  projectId: string,
) => Api.fetchProjectViews({ organizationId, projectId });

export const useProjectViews = ({
  config: inputConfig,
  organizationId,
  projectId,
}: UseProjectViewsParams) => {
  const isEnabled = !!organizationId && !!projectId;
  const config = getQueryConfig<Api.FetchProjectViewsResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createQueryKey({ organizationId, projectId })
    : undefined;

  const { data: projectViews, ...queryInfo } = useQuery(key, fetcher, config);

  const sortedProjectViews = projectViews?.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return { projectViews: sortedProjectViews, ...queryInfo };
};

type InvalidateCacheParams = Omit<KeyParams, 'projectId'> & {
  organizationId: string;
  projectId?: string;
};

export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([
      KEY_START,
      keyParams.organizationId,
      keyParams.projectId,
    ]),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useProjectViews',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

viewCacheRegister(
  [ViewCacheEvents.CREATE, ViewCacheEvents.UPDATE, ViewCacheEvents.DELETE],
  cacheRegisterInvalidator,
);
