import { Api } from '@monorepo/shared/apiClient';
import {
  LogFieldCacheEvents,
  logFieldCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/logFieldCacheRegister';
import {
  ResourcePropertyCacheEvents,
  resourcePropertyCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/resourcePropertyCacheRegister';
import {
  ViewCacheEvents,
  viewCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/viewCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';

type KeyParams = Api.FetchViewParams;
const KEY_START = 'view';

export const createQueryKey = ({
  organizationId,
  projectId,
  viewId,
}: KeyParams) => [KEY_START, organizationId, projectId, viewId] as const;

type Fetcher = Api.DataHookQueryFn<typeof createQueryKey, typeof Api.fetchView>;

type UseViewParams = Partial<Api.FetchViewParams> & {
  config?: QueryConfig<Api.FetchViewResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  projectId: string,
  viewId: string,
) => Api.fetchView({ organizationId, projectId, viewId });

export const useView = ({
  config: inputConfig,
  organizationId,
  projectId,
  viewId,
}: UseViewParams) => {
  const isEnabled = !!organizationId && !!projectId && !!viewId;
  const config = getQueryConfig<Api.FetchViewResponse>(inputConfig, isEnabled);
  const key = isEnabled
    ? createQueryKey({ organizationId, projectId, viewId })
    : undefined;
  const { data: view, ...queryInfo } = useQuery(key, fetcher, config);
  return {
    view,
    ...queryInfo,
  };
};

type QueryCache = ReturnType<typeof useView>['view'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

type InvalidateCacheParams = Partial<KeyParams>;

// allow invalidating a specific view
export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([
      KEY_START,
      keyParams.organizationId,
      keyParams.projectId,
      keyParams.viewId,
    ]),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useView',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

viewCacheRegister([ViewCacheEvents.DELETE], cacheRegisterInvalidator);

logFieldCacheRegister([LogFieldCacheEvents.UPDATE], cacheRegisterInvalidator);
resourcePropertyCacheRegister(
  [ResourcePropertyCacheEvents.UPDATE],
  cacheRegisterInvalidator,
);
