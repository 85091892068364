import { Button } from '@monorepo/shared/componentsV2/Button';
import { BaseModal } from '@monorepo/shared/componentsV2/modals/BaseModal';
import { FormTemplateFieldGroupResponse } from 'mapistry-shared';
import React from 'react';
import { Form } from 'react-final-form';
import { EditSectionNameForm } from './EditSectionNameForm';

export type EditSectionNameModalProps = {
  name: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formResponse: { name: string }) => Promise<void>;
  groups: FormTemplateFieldGroupResponse[];
};

export function EditSectionNameModal({
  name,
  isOpen,
  onClose,
  onSubmit,
  groups,
}: EditSectionNameModalProps) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ name }}
      subscription={{ invalid: true, pristine: true, submitting: true }}
    >
      {({ handleSubmit, pristine, invalid, submitting }) => (
        <BaseModal
          title="Edit section name"
          open={isOpen}
          onClose={onClose}
          maxWidth="md"
          buttons={
            <>
              <Button
                color="primary"
                disabled={submitting}
                onClick={onClose}
                type="button"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={pristine || invalid || submitting}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
              >
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </>
          }
        >
          <EditSectionNameForm groups={groups} currentName={name} />
        </BaseModal>
      )}
    </Form>
  );
}
