import {
  Content,
  Page,
} from '@monorepo/shared/componentsV2/fullPageWizard/styled/fullPageWizard';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { useToast } from '@monorepo/shared/contexts';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import { useWorkflow } from '@monorepo/shared/hooks/workflows/useWorkflow';
import { EditWorkflowHeader } from '@monorepo/workflowDataSetup/src/components/EditWorkflowHeader';
import { EmptyStepList } from '@monorepo/workflowDataSetup/src/components/EditWorkflowStepsPage/EmptyStepList';
import { WizardStep } from '@monorepo/workflowDataSetup/src/components/types';
import {
  CreateWorkflowStepRequest,
  EditWorkflowStepRequest,
  WorkflowStepOperationType,
  WorkflowStepResponse,
} from 'mapistry-shared';
import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useWorkflowStepCreate } from '../../hooks/useWorkflowStepCreate';
import { useWorkflowStepUpdate } from '../../hooks/useWorkflowStepUpdate';
import { EditStepModal } from './EditStepModal';
import { StepList } from './StepList';

function useOpenCloseEditStepModal() {
  const [currentlyOpenStepType, setCurrentlyOpenStepType] = useState<
    WorkflowStepOperationType | undefined
  >();
  const [currentlyOpenStepId, setCurrentlyOpenStepId] = useState<
    string | undefined
  >();

  const openModalForNewStep = useCallback(
    (stepType: WorkflowStepOperationType) => {
      setCurrentlyOpenStepId(undefined);
      setCurrentlyOpenStepType(stepType);
    },
    [],
  );

  const openModalForExistingStep = useCallback(
    (step: Pick<WorkflowStepResponse, 'id' | 'type'>) => {
      setCurrentlyOpenStepId(step.id);
      setCurrentlyOpenStepType(step.type);
    },
    [],
  );

  const closeStepModal = useCallback(() => {
    setCurrentlyOpenStepId(undefined);
    setCurrentlyOpenStepType(undefined);
  }, []);

  return {
    currentlyOpenStepId,
    currentlyOpenStepType,
    openModalForNewStep,
    openModalForExistingStep,
    closeStepModal,
  };
}

export function EditWorkflowStepsPage() {
  useDisableSubnav();
  const { organizationId, workflowId } = useParams();
  const stepListContainerRef = useRef<HTMLDivElement>(null);
  const stepsListAddButtonRef = useRef<HTMLButtonElement>(null);

  if (!organizationId || !workflowId) {
    throw new Error(
      "EditWorkflowStepsPage page was rendered with url that doesn't have organizationId or workflowId",
    );
  }

  const {
    closeStepModal,
    currentlyOpenStepId,
    currentlyOpenStepType,
    openModalForExistingStep,
    openModalForNewStep,
  } = useOpenCloseEditStepModal();

  const { showUserFriendlyErrorToast, success: showSuccessToast } = useToast();

  const [creator] = useWorkflowStepCreate({ config: { throwOnError: true } });
  const [updater] = useWorkflowStepUpdate({ config: { throwOnError: true } });

  const submitStep = useCallback(
    async (step: CreateWorkflowStepRequest | EditWorkflowStepRequest) => {
      const saveProps = { organizationId, workflowId, step };
      try {
        currentlyOpenStepId
          ? await updater({ ...saveProps, stepId: currentlyOpenStepId })
          : await creator(saveProps);

        showSuccessToast(
          `Workflow step has been ${
            currentlyOpenStepId ? 'edited.' : 'created.'
          }`,
        );
        closeStepModal();
      } catch (err) {
        showUserFriendlyErrorToast(
          err,
          `Cannot ${currentlyOpenStepId ? 'update' : 'save'} Workflow step.`,
          { autoHideDuration: 60 * 1000 },
        );
      }
    },
    [
      closeStepModal,
      creator,
      currentlyOpenStepId,
      organizationId,
      showSuccessToast,
      showUserFriendlyErrorToast,
      updater,
      workflowId,
    ],
  );

  const { workflow, isLoading } = useWorkflow({ organizationId, workflowId });

  const handleFocusOnDelete = useCallback(() => {
    // when there is only one step left, put focus on the container, otherwise on the add button
    // (ideally, we'd put it on the empty list add button instead, but that's a bit complicated with
    // it being hidden. The container isn't perfect, but good enough.)
    workflow?.steps.length === 1
      ? stepListContainerRef.current?.focus()
      : stepsListAddButtonRef.current?.focus();
  }, [workflow?.steps.length]);

  if (isLoading) {
    return (
      <Page>
        <EditWorkflowHeader wizardStep={WizardStep.STEPS} />
        <Loading />
      </Page>
    );
  }

  if (!workflow) {
    throw new Error("Couldn't get information about this workflow.");
  }

  return (
    <Page>
      <EditWorkflowHeader wizardStep={WizardStep.STEPS} />
      <Content mainRef={stepListContainerRef}>
        {workflow.steps.length ? (
          <StepList
            addButtonRef={stepsListAddButtonRef}
            onDeleteSuccess={handleFocusOnDelete}
            openModalForNewStep={openModalForNewStep}
            openModalForExistingStep={openModalForExistingStep}
            workflow={workflow}
          />
        ) : (
          <EmptyStepList
            onAddNew={openModalForNewStep}
            organizationId={organizationId}
          />
        )}
      </Content>
      {!!currentlyOpenStepType && (
        <EditStepModal
          isOpen={!!currentlyOpenStepType}
          onClose={closeStepModal}
          onSubmitStep={submitStep}
          organizationId={organizationId}
          stepType={currentlyOpenStepType}
          stepId={currentlyOpenStepId}
          workflow={workflow}
        />
      )}
    </Page>
  );
}
