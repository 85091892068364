import { useCallback } from 'react';
import {
  NavigateOptions,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { LinkEnd } from './types';

type LogSpecificLink<PathEnd extends LinkEnd> =
  `/vortex/organizations/${string}/sites/${string}/logs/${string}${PathEnd}`;

type GetLinkOnSameLog = <PathEnd extends LinkEnd>(
  pathEnd: PathEnd,
) => LogSpecificLink<PathEnd>;

interface LogSpecificLinkProps<PathEnd> {
  projectId: string;
  organizationId: string;
  logId: string;
  pathEnd: PathEnd;
}

function getLogSpecificLink<PathEnd extends LinkEnd>(
  props: LogSpecificLinkProps<PathEnd>,
): LogSpecificLink<PathEnd> {
  const { projectId, organizationId, logId, pathEnd } = props;
  return `/vortex/organizations/${organizationId}/sites/${projectId}/logs/${logId}${pathEnd}`;
}

function useProjectAndOrgAndLogFromPath() {
  const { projectId, organizationId, logId } = useParams();

  if (!organizationId || !projectId || !logId) {
    throw new Error(
      "useProjectAndOrgAndLogFromPath page was used from a url that doesn't have organizationId or projectId or logId",
    );
  }

  return { projectId, organizationId, logId };
}

/** To help easily navigate between different sub pages on the same log, while avoiding relative links. */
export const useLinkOnSameLog: GetLinkOnSameLog = (pathEnd) => {
  const { projectId, organizationId, logId } = useProjectAndOrgAndLogFromPath();
  return getLogSpecificLink({ projectId, organizationId, logId, pathEnd });
};

/** To help create dynamic links to navigate between different sub pages on the same log, while avoiding relative links. */
export function useDynamicLinkOnSameLog() {
  const { projectId, organizationId, logId } = useProjectAndOrgAndLogFromPath();

  const getLinkOnSameLog = useCallback<GetLinkOnSameLog>(
    (pathEnd) =>
      getLogSpecificLink({ organizationId, projectId, logId, pathEnd }),
    [organizationId, projectId, logId],
  );

  return { getLinkOnSameLog };
}

export const useNavigateOnSameLog = (pathEnd: LinkEnd) => {
  const linkOnSameLog = useLinkOnSameLog(pathEnd);
  const navigate = useNavigate();
  const navigateOnSameProject = (options?: NavigateOptions) =>
    navigate(linkOnSameLog, options);
  return navigateOnSameProject;
};
