import type { UpdateFormFieldsOrder } from '@monorepo/shared/apiClient/forms';
import { useReorderableItems } from '@monorepo/shared/componentsV2/dragAndDrop/useReorderableItems';
import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { FullScreenLoadingPage } from '@monorepo/shared/componentsV2/Loading';
import { useSkipWidgetLink } from '@monorepo/shared/componentsV2/SkipWidgetLink';
import { useToast } from '@monorepo/shared/contexts';
import { useDisableSubnav } from '@monorepo/shared/hooks/subnav/useDisableSubnav';
import { getPathToInspections } from '@monorepo/shared/routing';
import {
  FormTemplateFieldGroupResponse,
  FormTemplateFieldResponse,
} from 'mapistry-shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useFormTemplate } from '../../hooks/useFormTemplate';
import { useFormTemplateUpdate } from '../../hooks/useFormTemplateUpdate';
import { EditableSectionName } from './EditableSectionName';
import { EditFormTemplateHeader } from './EditFormTemplateHeader';
import { EditFormTemplateSectionFields } from './EditFormTemplateSectionFields';
import { FormTemplateUsage } from './FormTemplateUsage';
import { ReorderableFormTemplateSectionList } from './ReorderableFormTemplateSectionList';
import { ColumnWrapper, MainContent, Sidebar, StyledMain } from './styled';

export function EditFormTemplatePage() {
  useDisableSubnav();

  const { organizationId, formTemplateSlug, projectId } = useParams();

  const { formTemplate, isLoading } = useFormTemplate({
    slug: formTemplateSlug,
    organizationId,
  });

  const [updateFormTemplate] = useFormTemplateUpdate({
    config: { throwOnError: true },
  });

  const { showUserFriendlyErrorToast, success: showSuccessToast } = useToast();

  const { SkipLink, SkipLinkTarget } = useSkipWidgetLink();

  const [activeGroup, setActiveGroup] =
    useState<FormTemplateFieldGroupResponse>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldOrderGroups, setFieldOrderGroups] = useState<
    UpdateFormFieldsOrder[]
  >([]);
  const [fieldsHaveBeenReordered, setFieldsHaveBeenReordered] = useState(false);

  useEffect(() => {
    if (!isLoading && formTemplate && formTemplate.groups[0] && !activeGroup) {
      setActiveGroup(formTemplate.groups[0]);
      const initialFieldOrderGroups = formTemplate.groups.map((group) => ({
        fieldGroupId: group.id,
        fieldOrder: [],
      }));
      setFieldOrderGroups(initialFieldOrderGroups);
    }
  }, [
    activeGroup,
    setActiveGroup,
    isLoading,
    formTemplate,
    setFieldOrderGroups,
  ]);

  const updateFieldOrderGroups = useCallback(
    (
      fieldOrderGroup: UpdateFormFieldsOrder,
      orderedFields: FormTemplateFieldResponse[],
    ) => {
      if (!formTemplate) return;

      const groupWithUpdate = formTemplate.groups.find(
        (group) => group.id === fieldOrderGroup.fieldGroupId,
      );

      if (groupWithUpdate && orderedFields.length) {
        groupWithUpdate.fields = orderedFields.length
          ? orderedFields
          : groupWithUpdate?.fields;

        setFieldsHaveBeenReordered(true);

        const fieldOrderGroupsWithoutUpdate = fieldOrderGroups.filter(
          (e) => e.fieldGroupId !== fieldOrderGroup.fieldGroupId,
        );

        const updatedFieldOrderGroups = fieldOrderGroupsWithoutUpdate
          .concat(fieldOrderGroup)
          .filter((e) => e.fieldOrder.length);

        setFieldOrderGroups(updatedFieldOrderGroups);
      }
    },
    [fieldOrderGroups, formTemplate],
  );

  const {
    hasBeenReordered: sectionsHaveBeenReordered,
    orderedItems: orderedSections,
    updateItemOrder: updateFormTemplateSectionOrder,
    orderedItemIds: sectionOrderIds,
  } = useReorderableItems(formTemplate?.groups || []);

  const closeUrl = getPathToInspections(organizationId, projectId);

  const reorderSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      if (formTemplate && organizationId) {
        const fieldOrder = fieldsHaveBeenReordered
          ? fieldOrderGroups
          : undefined;

        const sectionOrder = sectionsHaveBeenReordered
          ? sectionOrderIds
          : undefined;

        await updateFormTemplate({
          organizationId,
          id: formTemplate.id,
          sectionOrder,
          fieldOrder,
        });
      }
      setFieldsHaveBeenReordered(false);
      showSuccessToast('Form saved.');
    } catch (err) {
      showUserFriendlyErrorToast(err, `Unable to save form.`, {
        dontAutoHide: true,
      });
    }

    setIsSubmitting(false);
  }, [
    setIsSubmitting,
    updateFormTemplate,
    showSuccessToast,
    showUserFriendlyErrorToast,
    formTemplate,
    organizationId,
    sectionsHaveBeenReordered,
    sectionOrderIds,
    fieldOrderGroups,
    fieldsHaveBeenReordered,
  ]);

  if (isLoading) return <FullScreenLoadingPage />;

  const activeGroupFields = activeGroup
    ? activeGroup.fields
    : formTemplate?.groups[0]?.fields;

  return !formTemplate || !activeGroup ? (
    <EmptyState primaryText="There was a problem loading the form." />
  ) : (
    <StyledMain>
      <EditFormTemplateHeader
        name={formTemplate.name}
        closeUrl={closeUrl}
        formIsDirty={sectionsHaveBeenReordered || fieldsHaveBeenReordered}
        isSubmitting={isSubmitting}
        handleSubmit={reorderSubmit}
      />
      <ColumnWrapper>
        <Sidebar>
          <SkipLink linkText="Skip to form fields" />
          <FormTemplateUsage formTemplateUsage={formTemplate.usage} />
          <ReorderableFormTemplateSectionList
            sortedFormTemplateSections={orderedSections}
            updateFormTemplateSectionOrder={updateFormTemplateSectionOrder}
            setActiveGroup={setActiveGroup}
            activeGroupId={activeGroup.id}
          />
        </Sidebar>
        <MainContent>
          <SkipLinkTarget />
          <EditableSectionName
            activeGroup={activeGroup}
            formTemplate={formTemplate}
          />
          <EditFormTemplateSectionFields
            fields={activeGroupFields}
            formTemplateId={formTemplate.id}
            groupId={activeGroup.id}
            updateFieldOrderGroups={updateFieldOrderGroups}
          />
        </MainContent>
      </ColumnWrapper>
    </StyledMain>
  );
}
