import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { useNavigateOnSameLog } from '@monorepo/shared/hooks/navigation/useLinkOnSame/useLinkOnSameLog';
import {
  fillHeightAndScrollable,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import React from 'react';
import styled from 'styled-components';
import { EditViewInfoModalProvider } from '../../contexts/EditViewInfoModalContext';
import { LogViewsTable } from './LogViewsTable';
import { ViewsToolbar } from './ViewsToolbar';

const Container = styled(Main)`
  ${verticalLayout}
`;

const StyledLogViewsTable = styled(LogViewsTable)`
  ${fillHeightAndScrollable}
`;

export function LogViewsTab() {
  const navigateToNewViewPage = useNavigateOnSameLog(`/views/new`);

  return (
    <ErrorBoundary>
      <EditViewInfoModalProvider>
        <Container>
          <ViewsToolbar
            onCreateNewView={navigateToNewViewPage}
            title="All Views"
          />
          <StyledLogViewsTable />
        </Container>
      </EditViewInfoModalProvider>
    </ErrorBoundary>
  );
}
