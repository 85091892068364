import { FieldTypePreview } from '@monorepo/shared/componentsV2/fieldDataType/FieldTypePreview/FieldTypePreview';
import CalendarIcon from '@svg/calendar.svg';
import CameraIcon from '@svg/camera.svg';
import CircleCheckIcon from '@svg/circle-check.svg';
import ClockIcon from '@svg/clock.svg';
import DocumentIcon from '@svg/document.svg';
import HashIcon from '@svg/hash.svg';
import InfoIcon2 from '@svg/information-2.svg';
import LocationIcon from '@svg/location.svg';
import MapIcon from '@svg/m-map-dashboard.svg';
import MultiLocationIcon from '@svg/multi-location.svg';
import MultiSelectIcon from '@svg/multi-select.svg';
import MultiUserIcon from '@svg/multi-user.svg';
import ParagraphIcon from '@svg/paragraph.svg';
import PhoneIcon from '@svg/phone.svg';
import RecurringIcon from '@svg/recurring.svg';
import SignatureIcon from '@svg/signature.svg';
import TextIcon from '@svg/text.svg';
import ToggleIcon from '@svg/toggle.svg';
import UserIcon from '@svg/user.svg';
import WeatherIcon from '@svg/weather.svg';
import WrenchIcon from '@svg/wrench.svg';
import {
  FormTemplateFieldResponse,
  FormTemplateFieldTypes,
} from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

interface FieldTypeFactoryProps {
  field: FormTemplateFieldResponse;
  openEditFieldModal: (fieldToEdit: FormTemplateFieldResponse) => void;
}

const StyledFormTemplateField = styled(FieldTypePreview)`
  margin: 0;

  /* so elements aren't see-through while being dragged over one another  */
  background-color: ${({ theme }) => theme.colors.white};
`;

export function FieldTypeFactory({
  field,
  openEditFieldModal,
}: FieldTypeFactoryProps) {
  const iconDetails = useMemo(() => {
    switch (field.type) {
      case FormTemplateFieldTypes.BOOLEAN:
        return {
          icon: <ToggleIcon />,
          tooltip: 'Boolean',
        };
      case FormTemplateFieldTypes.DATE:
        return {
          icon: <CalendarIcon />,
          tooltip: 'Date',
        };
      case FormTemplateFieldTypes.DOCUMENT_UPLOADER:
        return {
          icon: <DocumentIcon />,
          tooltip: 'Document Uploader',
        };
      case FormTemplateFieldTypes.EMISSION_OBSERVATION:
        return {
          icon: <RecurringIcon />,
          tooltip: 'Emission Observation',
        };
      case FormTemplateFieldTypes.EXPLANATION_AREA:
        return {
          icon: <InfoIcon2 />,
          tooltip: 'Explanation Area',
        };
      case FormTemplateFieldTypes.MAP_AREA:
        return {
          icon: <MapIcon />,
          tooltip: 'Map Area',
        };
      case FormTemplateFieldTypes.MULTI_LOCATION:
        return {
          icon: <MultiLocationIcon />,
          tooltip: 'Multi-Location',
        };
      case FormTemplateFieldTypes.MULTI_SELECT:
        return {
          icon: <MultiSelectIcon />,
          tooltip: 'Multi-Select',
        };
      case FormTemplateFieldTypes.MULTI_SELECT_WITH_FREE_FORM:
        return {
          icon: <MultiSelectIcon />,
          tooltip: 'Multi-Select with Free Form',
        };
      case FormTemplateFieldTypes.MULTI_USER:
        return {
          icon: <MultiUserIcon />,
          tooltip: 'Multi-User',
        };
      case FormTemplateFieldTypes.NUMBER:
        return {
          icon: <HashIcon />,
          tooltip: 'Number',
        };
      case FormTemplateFieldTypes.PHONE_NUMBER:
        return {
          icon: <PhoneIcon />,
          tooltip: 'Phone Number',
        };
      case FormTemplateFieldTypes.PHOTO_CAROUSEL:
        return {
          icon: <CameraIcon />,
          tooltip: 'Photos',
        };
      case FormTemplateFieldTypes.SIGNATURE:
        return {
          icon: <SignatureIcon />,
          tooltip: 'Signature',
        };
      case FormTemplateFieldTypes.SINGLE_LOCATION:
        return {
          icon: <LocationIcon />,
          tooltip: 'Single Location',
        };
      case FormTemplateFieldTypes.SINGLE_SELECT:
        return {
          icon: <CircleCheckIcon />,
          tooltip: 'Single Select',
        };
      case FormTemplateFieldTypes.SINGLE_SELECT_WITH_FREE_FORM:
        return {
          icon: <CircleCheckIcon />,
          tooltip: 'Single Select with Free Form',
        };
      case FormTemplateFieldTypes.TASKS:
        return {
          icon: <WrenchIcon />,
          tooltip: 'Tasks',
        };
      case FormTemplateFieldTypes.TEXT:
        return {
          icon: <TextIcon />,
          tooltip: 'Text',
        };
      case FormTemplateFieldTypes.TEXT_AREA:
        return {
          icon: <ParagraphIcon />,
          tooltip: 'Text Area',
        };
      case FormTemplateFieldTypes.TIME:
        return {
          icon: <ClockIcon />,
          tooltip: 'Time',
        };
      case FormTemplateFieldTypes.USER:
        return {
          icon: <UserIcon />, // we now have 2 very similar icons - PersonIcon and UserIcon
          tooltip: 'User',
        };
      case FormTemplateFieldTypes.WEATHER:
        return {
          icon: <WeatherIcon />,
          tooltip: 'Weather',
        };
      default: {
        const exhaustive: never = field.type;
        throw new Error(`Invalid field type - ${exhaustive}`);
      }
    }
  }, [field]);

  const editField = useCallback(() => {
    openEditFieldModal(field);
  }, [field, openEditFieldModal]);

  return (
    <StyledFormTemplateField
      icon={iconDetails.icon}
      iconTooltip={iconDetails.tooltip}
      fieldName={field.name}
      outerElement="div"
      actionMenuOptions={[
        {
          displayName: 'Edit',
          onClick: editField,
        },
      ]}
    />
  );
}
