import { ActionMenu } from '@monorepo/shared/componentsV2/ActionMenu';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useEditViewInfoModal } from '../../hooks/useEditViewInfoModal';
import { useViewDeleteWithConfirm } from '../../hooks/useViewDelete';

interface ViewsTableActionsProps {
  viewId: string;
  viewOriginId: string;
  viewName: string;
}

export function ViewsTableActions({
  viewId,
  viewOriginId,
  viewName,
}: ViewsTableActionsProps) {
  const { organizationId, projectId } = useParams();
  const { openModal } = useEditViewInfoModal();
  const handleDelete = useViewDeleteWithConfirm({
    organizationId,
    projectId,
    viewId,
    viewName,
  });

  const handleEditDetails = useCallback(
    () => openModal(viewId, viewOriginId),
    [openModal, viewOriginId, viewId],
  );

  return (
    <ActionMenu
      altText={`Action menu for ${viewName} View`}
      options={[
        { displayName: 'Edit details', onClick: handleEditDetails },
        { displayName: 'Delete', onClick: handleDelete },
      ]}
    />
  );
}
