import { isValid, parse } from 'date-fns';
import { FieldValidator } from 'final-form';

export const IS_REQUIRED_ERROR = 'Required';

export const isRequired = (value?: unknown) => {
  const trimmed = typeof value === 'string' ? value.trim() : value;
  const isEmpty = trimmed == null || trimmed === '';
  return isEmpty ? IS_REQUIRED_ERROR : undefined;
};

export const hasAtLeastOneValue = (value?: unknown[]) =>
  !!value?.length &&
  value?.every((v) => (typeof v === 'string' ? !!v.trim() : !!v))
    ? undefined
    : 'Please specify at least one option';

export const hasUniqueValues = (value?: string[]): string | undefined => {
  if (!value || !Array.isArray(value)) {
    return undefined;
  }

  return value.every(
    (v) => value.filter((other) => other.trim() === v.trim()).length === 1,
  )
    ? undefined
    : 'All values must be unique';
};

export const compose =
  (
    ...validators: (
      | ((value?: unknown) => string | undefined)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | FieldValidator<any>
    )[]
  ) =>
  (value: unknown, allValues: object) =>
    validators.reduce<string | undefined>(
      (err, validator) => err || validator(value, allValues),
      undefined,
    );

const invalidDateMessage = 'Invalid date format';

export const isDate = (value: unknown) =>
  isValid(value) ? undefined : invalidDateMessage;

export const isDateOrNullish = (value: unknown) =>
  value == null || value === '' ? undefined : isDate(value);

const invalidTimeMessage = 'Invalid time format';

export const isTimeStringOrNullish = (value: string | null | undefined) =>
  value == null || value === '' || isValid(parse(value, 'HH:mm', new Date()))
    ? undefined
    : invalidTimeMessage;

export const makeIsGreaterThan = (minValue: number) => (value: unknown) => {
  if (value == null || value === '') return undefined;
  const parsed = typeof value === 'number' ? value : Number(value);
  if (Number.isNaN(parsed)) {
    return 'Invalid number';
  }
  return parsed > minValue ? undefined : `Must be greater than ${minValue}`;
};

export const makeIsNotInList =
  (list: unknown[], labelForListItem: string) => (value: unknown) => {
    if (list.includes(value))
      return `This ${labelForListItem} is already used. Please choose a unique ${labelForListItem}`;
    return undefined;
  };

export const makeIsNoLongerThan = (maxLength: number) => (value: string) => {
  if (value.length >= maxLength)
    return `Must be ${maxLength} or fewer characters.`;
  return undefined;
};
