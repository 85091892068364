import { Tooltip } from '@monorepo/shared/componentsV2/Tooltip';
import DuplicateCardsIcon from '@svg/duplicate-cards.svg';
import React from 'react';
import styled from 'styled-components';
import { SectionButton } from './styled';
import type { FormTemplateSectionType } from './types';

const DuplicableIndictor = styled(Tooltip)`
  position: absolute;
  top: 15px;
  right: 10px;
  align-items: center;
  padding: 0.35rem;
  padding-bottom: 0.1rem;
  background-color: ${({ theme }) => theme.colors.grayeee};
  border-radius: 0.2rem;

  & svg {
    height: 1rem;
  }
`;

export function FormTemplateSection({
  name,
  clickHandler,
  isDuplicable,
  isActive,
}: FormTemplateSectionType) {
  const activeClass = isActive ? 'active' : '';
  return (
    <span>
      <SectionButton onClick={clickHandler} className={activeClass}>
        {name}
        {isDuplicable && (
          <DuplicableIndictor title="This is a duplicable section.">
            <div>
              <DuplicateCardsIcon />
            </div>
          </DuplicableIndictor>
        )}
      </SectionButton>
    </span>
  );
}
