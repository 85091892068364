import {
  compose,
  isRequired,
  makeIsNoLongerThan,
  makeIsNotInList,
} from '@monorepo/shared/utils/validators';
import { FormTemplateFieldGroupResponse } from 'mapistry-shared';
import React from 'react';
import { ModalFieldWrapper, ModalTextField } from '../styled';

type EditSectionNameFormProps = {
  currentName: string;
  groups: FormTemplateFieldGroupResponse[];
};

export function EditSectionNameForm({
  currentName,
  groups,
}: EditSectionNameFormProps) {
  const groupNames = groups
    .filter((group) => group.name !== currentName)
    .map((group) => group.name);

  // this const should be moved to  mapistry-shared and be used by backend validation
  const MAX_NAME_LENGTH = 250;
  return (
    <ModalFieldWrapper>
      <ModalTextField
        label="Name"
        name="name"
        validate={compose(
          isRequired,
          makeIsNotInList(groupNames, 'name'),
          makeIsNoLongerThan(MAX_NAME_LENGTH),
        )}
        required
      />
    </ModalFieldWrapper>
  );
}
