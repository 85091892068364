import { downloadViewResults } from '@monorepo/shared/apiClient/logs';
import { Breadcrumbs } from '@monorepo/shared/componentsV2/Breadcrumbs';
import { Button } from '@monorepo/shared/componentsV2/Button';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { MenuButton } from '@monorepo/shared/componentsV2/MenuButton';
import { Toolbar } from '@monorepo/shared/componentsV2/Toolbar';
import { RightActions } from '@monorepo/shared/componentsV2/Toolbar/styled';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import { useToast } from '@monorepo/shared/contexts';
import {
  useHasProjectSettingsPermissions,
  useHasProjectUpdatePermissions,
} from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import { bodySmall, header4 } from '@monorepo/shared/styles/text';
import TrashIcon from '@svg/trash.svg';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { useEditViewInfoModal } from '../../hooks/useEditViewInfoModal';
import { useView } from '../../hooks/useView';
import { useViewDeleteWithConfirm } from '../../hooks/useViewDelete';

const PATH_TO_ALL_VIEWS = '../../../..';

const StyledToolbar = styled(Toolbar)`
  padding: 1rem 0;

  & ${RightActions} {
    align-self: flex-start;
    height: auto;
  }
`;

const LeftSideContainer = styled.div`
  flex-grow: 1;
  padding: 0 2rem 0 0;
`;

const Description = styled.div`
  ${bodySmall}
  padding-top: 1rem;
  color: ${({ theme }) => theme.colors.gray666};
  white-space: pre-wrap;
`;

const StyledMenuButton = styled(MenuButton)`
  min-width: 7.5rem;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  ${header4}
  padding: 0;
`;

export function ViewDetailToolbar() {
  const { logId, organizationId, projectId, viewId } = useParams();
  if (!organizationId || !projectId || !viewId) {
    throw new Error(
      "ViewDetailToolbar component was rendered in a page with url that doesn't have projectId, organizationId or viewId",
    );
  }
  const navigate = useNavigate();
  const canSubmitDataForProject = useHasProjectUpdatePermissions(projectId);
  const canEditViews = useHasProjectSettingsPermissions(projectId);
  const { openModal } = useEditViewInfoModal();
  const { isLoading, view } = useView({ organizationId, projectId, viewId });
  const deleter = useViewDeleteWithConfirm({
    organizationId,
    projectId,
    viewId,
    viewName: view?.name,
  });
  const handleDelete = useCallback(async () => {
    const result = await deleter();
    if (result.success) {
      navigate(PATH_TO_ALL_VIEWS);
    }
  }, [deleter, navigate]);
  const crumbs = useMemo(
    () => [
      { label: 'All Views', to: PATH_TO_ALL_VIEWS },
      { label: view?.name || 'Unknown View', to: '.' },
    ],
    [view?.name],
  );

  const { confirm } = useModal();
  const { showUserFriendlyErrorToast } = useToast();

  const handleExport = useCallback(async () => {
    try {
      if (!view) {
        throw new Error("Couldn't load View details");
      }
      // todo@workflows: get rid of the dependency on logId here
      if (!logId) {
        throw new Error(
          "ViewDetailToolbar: trying to export view query results on a page that doesn't have logId in its url",
        );
      }
      await confirm({
        title: 'Export View Data',
        description: (
          <>
            Export results for <b>{view.name}</b> View?
          </>
        ),
        confirmButtonText: 'Export',
        afterConfirmButtonText: 'Processing...',
        onConfirmAsync: () =>
          downloadViewResults({
            viewId,
            logId,
            organizationId,
            projectId,
          }),
      });
    } catch (error) {
      showUserFriendlyErrorToast(error, 'Unable to process data for export.');
    }
  }, [
    logId,
    viewId,
    confirm,
    view,
    organizationId,
    projectId,
    showUserFriendlyErrorToast,
  ]);

  const handleEditDetails = useCallback(() => {
    // todo@workflows: get rid of the dependency on logId here
    if (!logId) {
      throw new Error(
        "ViewDetailToolbar: trying to edit view details on a page that doesn't have logId in its url",
      );
    }
    openModal(viewId, logId);
  }, [openModal, logId, viewId]);
  const handleEditConfiguration = useCallback(
    () => navigate('../../../edit'),
    [navigate],
  );

  return isLoading ? (
    <Loading />
  ) : (
    <StyledToolbar
      leftActions={
        <LeftSideContainer>
          <StyledBreadcrumbs crumbs={crumbs} />
          {view?.description && <Description>{view.description}</Description>}
        </LeftSideContainer>
      }
      rightActions={
        canSubmitDataForProject ? (
          <ButtonsGroup>
            <Button onClick={handleExport}>Export</Button>
            {canEditViews && (
              <>
                <IconButton onClick={handleDelete}>
                  <TrashIcon />
                  <VisuallyHidden>Delete</VisuallyHidden>
                </IconButton>
                <StyledMenuButton
                  label="Edit View"
                  options={[
                    { onClick: handleEditDetails, displayName: 'Edit details' },
                    {
                      onClick: handleEditConfiguration,
                      displayName: 'Edit configuration',
                    },
                  ]}
                />
              </>
            )}
          </ButtonsGroup>
        ) : null
      }
      size="auto"
      variant="noDropShadow"
    />
  );
}
