import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { HorizontalNavigationTabs } from '@monorepo/shared/componentsV2/NavigationTabs/HorizontalNavigationTabs';
import { useLinkOnSameProject } from '@monorepo/shared/hooks/navigation/useLinkOnSame/useLinkOnSameProject';
import { useCurrentProject } from '@monorepo/shared/hooks/useCurrentProject';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { useFeatureFlags } from 'mapistry-shared/api';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { LogsPageHeader } from './LogsPage/LogsPageHeader';

const Page = styled.div`
  ${verticalLayout}
  ${page}
`;

const StyledLogsPageHeader = styled(LogsPageHeader)`
  background-color: ${({ theme }) => theme.colors.blue04};
`;

const StyledTabs = styled(HorizontalNavigationTabs)`
  background-color: ${({ theme }) => theme.colors.blue04};
`;

const StyledMain = styled(Main)`
  ${fillHeightAndScrollable}
`;

const TabContent = styled.div`
  ${fillHeightAndScrollable}
`;

export interface LogsPageOutletContext {
  setIsSearchVisible(arg: boolean): void;
  filter: string;
}

export function LogsAndViewsPage() {
  const { project } = useCurrentProject();
  const { areWorkflowsEnabled } = useFeatureFlags();

  const [filter, setFilter] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  // clear filter when switching projects
  useEffect(() => {
    setFilter('');
  }, [project?.id]);

  const logsPageLink = useLinkOnSameProject('/logs');
  const viewsPageLink = useLinkOnSameProject('/logs/views');

  const tabOptions = useMemo(
    () => [
      { label: 'All Logs', href: logsPageLink },
      ...(areWorkflowsEnabled ? [{ label: 'Views', href: viewsPageLink }] : []),
    ],
    [logsPageLink, areWorkflowsEnabled, viewsPageLink],
  );

  const outletContextValue = useMemo<LogsPageOutletContext>(
    () => ({ setIsSearchVisible, filter }),
    [filter],
  );

  if (!project) {
    return <Loading />;
  }

  return (
    <Page>
      <StyledLogsPageHeader
        onSearch={isSearchVisible ? setFilter : undefined}
        filter={filter}
      />
      <StyledTabs label="Logs or Views" options={tabOptions} />
      <StyledMain>
        <TabContent>
          <ErrorBoundary>
            <Outlet context={outletContextValue} />
          </ErrorBoundary>
        </TabContent>
      </StyledMain>
    </Page>
  );
}
