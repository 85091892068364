import { Api } from '@monorepo/shared/apiClient';
import { FormTemplateResponse } from 'mapistry-shared';
import { MutationConfig, useMutation } from 'react-query';
import { setCache } from './useFormTemplate';

type UseFormTemplateGroupUpdateParams = {
  config?: MutationConfig<FormTemplateResponse, Api.ErrorResponse>;
};

export const useFormTemplateGroupUpdate = (
  params?: UseFormTemplateGroupUpdateParams,
) =>
  useMutation(Api.updateFormTemplateGroup, {
    ...params?.config,
    onSuccess: (formTemplate, mutationFuncParams) => {
      const { organizationId } = mutationFuncParams;
      const { slug } = formTemplate;

      setCache({ slug, organizationId }, formTemplate);
      return params?.config?.onSuccess?.(formTemplate, mutationFuncParams);
    },
  });
