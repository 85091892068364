import React from 'react';
import { ModalFieldWrapper, ModalTextField } from '../../styled';

export function EditHelperTextField() {
  return (
    <ModalFieldWrapper>
      <ModalTextField
        label="Helper text"
        name="helpText"
        tooltipText="Optional. Helper text is viewed by customers when they hover over the “i” to the right of the field name. If you want to be sure that users see the text, make it subtext, not helper text."
      />
    </ModalFieldWrapper>
  );
}
