import { EmptyState } from '@monorepo/shared/componentsV2/EmptyState';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { Loading } from '@monorepo/shared/componentsV2/Loading';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { viewsProductGuideUrl } from '@monorepo/shared/externalLinks/productGuide';
import { focusOutline } from '@monorepo/shared/styles/focus';
import {
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import { header4 } from '@monorepo/shared/styles/text';
import { EdpViewResponse } from 'mapistry-shared';
import React, { useLayoutEffect, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { EditViewInfoModalProvider } from '../../contexts/EditViewInfoModalContext';
import {
  QueryStepsProvider,
  useQuerySteps,
} from '../../contexts/QueryStepsContext';
import { useLogQuery } from '../../hooks/useLogQuery';
import { useView } from '../../hooks/useView';
import { SingleLogPageOutletContext } from '../SingleLogPage';
import { ViewResultsTable } from '../ViewResultsTable';
import { EditViewHeader } from './EditViewHeader';
import { QueryStepsAccordionGroup } from './QueryStepsAccordionGroup';
import { QueryStepToolbar } from './QueryStepToolbar';

const Page = styled(Main)`
  ${verticalLayout}
  ${page}
`;

const Content = styled.div`
  ${fillHeightAndScrollable}

  &:focus-visible {
    ${focusOutline({ $outlineOffset: '-2px' })}
  }
`;

const StyledQuerySteps = styled(QueryStepsAccordionGroup)`
  padding-bottom: 3rem;
`;

const StyledEmptyState = styled(EmptyState)`
  margin-bottom: calc(75px - 1rem);
`;

const Header2 = styled.h2`
  ${header4}
  padding: 1rem 1.5rem;
  margin: 0;
`;

const StyledViewResultsTable = styled(ViewResultsTable)`
  height: auto;
`;

const buttonConfig = {
  label: 'Learn about Views',
  onClick: () => window.open(viewsProductGuideUrl, '_blank'),
};

function EditViewPageContent({ view }: { view: EdpViewResponse | undefined }) {
  const { getInProgressQuerySteps, validQuerySteps } = useQuerySteps();

  const updatedView = useMemo(
    () => ({ ...view, querySteps: validQuerySteps }),
    [validQuerySteps, view],
  );

  const { logId, organizationId, projectId } = useParams();
  if (!logId || !organizationId || !projectId) {
    throw new Error(
      "EditViewPageContent component was rendered in a page with url that doesn't have logId, projectId or organizationId",
    );
  }
  const {
    isIdle: queryIsIdle,
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
    queryResult,
  } = useLogQuery({
    logId,
    organizationId,
    projectId,
    query: { steps: validQuerySteps || [] },
    config: { enabled: !!validQuerySteps },
  });

  const isLoading = queryIsFetching || queryIsLoading || queryIsIdle;

  return (
    <ErrorBoundary>
      <EditViewInfoModalProvider>
        <Page>
          <EditViewHeader view={updatedView} originId={logId} />
          <Content>
            {getInProgressQuerySteps().length ? (
              <>
                <StyledQuerySteps />
                <QueryStepToolbar />
              </>
            ) : (
              <>
                <QueryStepToolbar />
                <StyledEmptyState
                  actionButtonConfigs={buttonConfig}
                  primaryText="Add a query step above to begin transforming your data."
                  secondaryText="Adding query steps will allow you to save relevant views of your data so you can more easily understand the health of your requirements."
                />
              </>
            )}
            <Header2>Data Preview</Header2>
            <StyledViewResultsTable
              isLoading={isLoading}
              queryResult={queryResult}
            />
          </Content>
        </Page>
      </EditViewInfoModalProvider>
    </ErrorBoundary>
  );
}

export function EditViewPage() {
  const { logId, organizationId, projectId, viewId } = useParams();
  if (!logId || !organizationId || !projectId) {
    throw new Error(
      "EditViewPage was rendered with a url that doesn't have logId, projectId or organizationId",
    );
  }
  const { hideHeader, hideTabs, showHeader, showTabs } =
    useOutletContext<SingleLogPageOutletContext>();

  useLayoutEffect(() => {
    hideHeader();
    hideTabs();

    return () => {
      showHeader();
      showTabs();
    };
  }, [hideHeader, hideTabs, showHeader, showTabs]);

  const { view, isLoading } = useView({
    organizationId,
    projectId,
    viewId,
  });
  return isLoading ? (
    <Loading />
  ) : (
    <QueryStepsProvider
      logId={logId}
      organizationId={organizationId}
      projectId={projectId}
      view={view}
    >
      <EditViewPageContent view={view} />
    </QueryStepsProvider>
  );
}
