import { isRequired } from '@monorepo/shared/utils/validators';
import React from 'react';
import { ModalFieldWrapper, ModalTextField } from '../styled';

// this is rendered within FinalForm so the inputs are managed by it
export function EditExplanationAreaFieldForm() {
  return (
    <>
      <ModalFieldWrapper>
        <ModalTextField
          label="Field Name"
          name="name"
          validate={isRequired}
          required
          dontSaveSpaceForErrorText
        />
      </ModalFieldWrapper>
      <ModalFieldWrapper>
        <ModalTextField
          label="Explanation Text"
          name="helpText"
          validate={isRequired}
          required
          rowsMax={15}
          multiline
        />
      </ModalFieldWrapper>
    </>
  );
}
