import { Api } from '@monorepo/shared/apiClient';
import {
  ViewCacheEvents,
  viewCacheRegister,
} from '@monorepo/shared/cacheRegisters/data2/viewCacheRegister';
import {
  getKeyParamsForInvalidation,
  getQueryConfig,
} from '@monorepo/shared/utils/queryUtils';
import { queryCache, QueryConfig, useQuery } from 'react-query';

type KeyParams = Api.FetchLogViewsParams;
const KEY_START = 'logViews';

export const createQueryKey = ({
  logId,
  organizationId,
  projectId,
}: KeyParams) => [KEY_START, organizationId, logId, projectId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createQueryKey,
  typeof Api.fetchLogViews
>;

type UseViewsParams = Partial<Api.FetchLogViewsParams> & {
  config?: QueryConfig<Api.FetchLogViewsResponse, Api.ErrorResponse>;
};

const fetcher: Fetcher = (
  _: string,
  organizationId: string,
  logId: string,
  projectId: string,
) => Api.fetchLogViews({ logId, organizationId, projectId });

// todo@workflows: Maybe this can "just" filter useProjectViews
export const useLogViews = ({
  config: inputConfig,
  logId,
  organizationId,
  projectId,
}: UseViewsParams) => {
  const isEnabled = !!logId && !!organizationId && !!projectId;
  const config = getQueryConfig<Api.FetchLogViewsResponse>(
    inputConfig,
    isEnabled,
  );
  const key = isEnabled
    ? createQueryKey({ logId, organizationId, projectId })
    : undefined;
  const { data: views, ...queryInfo } = useQuery(key, fetcher, config);
  return { views, ...queryInfo };
};

type QueryCache = ReturnType<typeof useLogViews>['views'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function getCache(keyParams: KeyParams): QueryCache {
  return queryCache.getQueryData<QueryCache>(createQueryKey(keyParams));
}

export function setCache(
  keyParams: KeyParams,
  newItems: NonEmptyQueryCache,
): void {
  queryCache.setQueryData(createQueryKey(keyParams), newItems);
}

type InvalidateCacheParams = Omit<KeyParams, 'logId' | 'projectId'> & {
  logId?: string;
  projectId?: string;
};

export async function invalidateCache(
  keyParams: InvalidateCacheParams,
): Promise<void> {
  await queryCache.invalidateQueries(
    getKeyParamsForInvalidation([
      KEY_START,
      keyParams.organizationId,
      keyParams.logId,
      keyParams.projectId,
    ]),
  );
}

const cacheRegisterInvalidator = {
  hookName: 'useLogViews',
  callback: (keyParams: InvalidateCacheParams) => invalidateCache(keyParams),
};

viewCacheRegister(
  [ViewCacheEvents.CREATE, ViewCacheEvents.UPDATE, ViewCacheEvents.DELETE],
  cacheRegisterInvalidator,
);
