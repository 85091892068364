import React from 'react';
import { ModalFieldWrapper, ModalTextField } from '../../styled';

export function EditSubtextField() {
  return (
    <ModalFieldWrapper>
      <ModalTextField
        label="Subtext"
        name="nameSubtext"
        tooltipText="Optional. The subtext will be displayed under the field name."
      />
    </ModalFieldWrapper>
  );
}
