import { FormTemplateFieldTypes } from 'mapistry-shared';
import React from 'react';
import { EditExplanationAreaFieldForm } from './EditExplanationAreaFieldForm';
import { EditGenericFieldForm } from './GenericFieldForm/EditGenericFieldForm';

type EditFieldFormProps = {
  fieldType: FormTemplateFieldTypes;
};

export function EditFieldForm({ fieldType }: EditFieldFormProps) {
  switch (fieldType) {
    case FormTemplateFieldTypes.EXPLANATION_AREA:
      return <EditExplanationAreaFieldForm />;
    default:
      return <EditGenericFieldForm />;
  }
}
