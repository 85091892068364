import MuiChip from '@material-ui/core/Chip';
import React from 'react';
import styled from 'styled-components';

type BadgeVariant = 'round' | 'standard';

const Chip = styled(MuiChip)<{ $variant: BadgeVariant }>`
  height: 1.375rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray666};
  background-color: ${({ theme }) => theme.colors.grayeee};
  ${({ $variant }) => $variant === 'standard' && 'border-radius: 3px'};

  & span {
    padding: 0 0.5rem;
  }
`;

const PrimaryChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.green};
  background-color: ${({ theme }) => theme.colors.green24};
`;

const SecondaryChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.lightBlue};
  background-color: ${({ theme }) => theme.colors.lightBlue08};
`;

const DangerChip = styled(Chip)`
  color: ${({ theme }) => theme.colors.darkRed};
  background-color: ${({ theme }) => theme.colors.red32};
`;

type BadgeProps = React.ComponentProps<typeof Chip>;

export function Badge({ color, variant = 'standard', ...props }: BadgeProps) {
  switch (color) {
    case 'primary':
      return <PrimaryChip color={color} $variant={variant} {...props} />;
    case 'secondary':
      return <SecondaryChip color={color} $variant={variant} {...props} />;
    case 'danger':
      return <DangerChip color={color} $variant={variant} {...props} />;
    default:
      return <Chip $variant={variant} {...props} />;
  }
}
