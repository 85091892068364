import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateViewCache,
  ViewCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/viewCacheRegister';
import { useToast } from '@monorepo/shared/contexts/ToastContext';
import { useModal } from '@monorepo/shared/hooks/useModalV2';
import React, { useCallback } from 'react';
import { MutationConfig, useMutation } from 'react-query';

type UseViewDeleteParams = {
  config?: MutationConfig<Api.DeleteViewResponse, Api.ErrorResponse>;
};

export const useViewDelete = (params?: UseViewDeleteParams) =>
  useMutation(Api.deleteView, {
    ...params?.config,
    onSuccess: (_, { organizationId, projectId, viewId }) => {
      // Invalidate any RQ cache that might use information about Views
      invalidateViewCache(ViewCacheEvents.DELETE, {
        organizationId,
        projectId,
        viewId,
      });
    },
    onError: (error, deleteViewParams, rollback) =>
      params?.config?.onError?.(error, deleteViewParams, rollback),
  });

type UseViewDeleteWithConfirmParams = UseViewDeleteParams & {
  organizationId?: string;
  projectId?: string;
  viewId: string;
  viewName?: string;
};

export const useViewDeleteWithConfirm = ({
  config,
  organizationId,
  projectId,
  viewId,
  viewName,
}: UseViewDeleteWithConfirmParams) => {
  const { confirm } = useModal();
  const { showUserFriendlyErrorToast, success } = useToast();
  const [deleter] = useViewDelete({
    config: { ...config, throwOnError: true },
  });

  // function passed to confirm to be executed when the confirm button is clicked
  const handleDeleteConfirm = useCallback(
    async (isConfirmed) => {
      if (isConfirmed && organizationId && projectId && viewId) {
        await deleter({
          viewId,
          organizationId,
          projectId,
        });
      }
    },
    [deleter, organizationId, projectId, viewId],
  );

  return useCallback(async () => {
    const result = { success: false };
    try {
      const isConfirmed = await confirm({
        title: 'Delete View',
        description: (
          <>
            Are you sure you want to delete <b>{viewName}</b>?
          </>
        ),
        cancelButtonText: 'Keep View',
        confirmButtonText: 'Delete View',
        afterConfirmButtonText: 'Deleting...',
        danger: true,
        onConfirmAsync: handleDeleteConfirm,
      });
      if (isConfirmed) {
        success(`${viewName} has been deleted.`);
        return { ...result, success: true };
      }
    } catch (err) {
      showUserFriendlyErrorToast(err, `Unable to delete ${viewName}.`);
      return result;
    }
    return result;
  }, [
    confirm,
    viewName,
    handleDeleteConfirm,
    success,
    showUserFriendlyErrorToast,
  ]);
};
