import MoreVert from '@material-ui/icons/MoreVert';
import { IconButton } from '@monorepo/shared/componentsV2/Button/IconButton';
import { VisuallyHidden } from '@monorepo/shared/componentsV2/VisuallyHidden';
import React from 'react';
import { Menu, MenuColor } from './Menu';

type ActionMenuProps = {
  options: React.ComponentProps<typeof Menu>['options'];
  altText?: string;
  color?: MenuColor;
};

export function ActionMenu({
  options,
  altText = 'Action menu',
  color = 'primary',
}: ActionMenuProps) {
  return (
    <Menu
      color={color}
      options={options}
      buttonElement={
        <IconButton color={color}>
          <MoreVert />
          <VisuallyHidden>{altText}</VisuallyHidden>
        </IconButton>
      }
    />
  );
}
