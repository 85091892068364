import { Accordion } from '@monorepo/shared/componentsV2/Accordion';
import { Badge } from '@monorepo/shared/componentsV2/Badge';
import { Tooltip } from '@monorepo/shared/componentsV2/Tooltip';
import { FormTemplateUsageResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const Summary = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0.5rem;
`;

const StyledLabel = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const StyledAccordion = styled(Accordion)`
  .MuiAccordionDetails-root {
    padding: 0;
  }

  .accordion-details-container {
    max-height: 20rem;
    overflow: auto;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SiteContainer = styled.div`
  padding: 0.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.grayeee};
`;

const SiteName = styled.div`
  margin: 0.3rem 1rem;
  color: ${({ theme }) => theme.colors.gray999};
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CalendarItem = styled.div`
  margin: 0.3rem 1rem;
`;

declare type FormTemplateUsageProps = {
  formTemplateUsage?: FormTemplateUsageResponse;
};

export function FormTemplateUsage({
  formTemplateUsage,
}: FormTemplateUsageProps) {
  const projectRows = useMemo(
    () =>
      formTemplateUsage?.usage.map((usage) => {
        const calendarRows = usage.formCalendars.map((fc) => (
          <CalendarItem key={fc.id}>{fc.name}</CalendarItem>
        ));
        return (
          <SiteContainer key={usage.projectId}>
            <SiteName>{usage.projectName.toUpperCase()}</SiteName>
            <CalendarContainer>{calendarRows}</CalendarContainer>
          </SiteContainer>
        );
      }),
    [formTemplateUsage],
  );

  const detailsContent = <DetailsContainer>{projectRows}</DetailsContainer>;

  const summaryContent = (
    <Summary>
      <StyledLabel>
        <span>Form instances</span>
        <Tooltip
          aria-label="These are the places this form is used across your sites. To edit this list, please reach out to support@mapistry.com for assistance."
          title={
            <span>
              These are the places this form is used across your sites. To edit
              this list, please reach out to{' '}
              <strong>support@mapistry.com</strong> for assistance.
            </span>
          }
        />
      </StyledLabel>
      <Badge
        color="secondary"
        label={formTemplateUsage?.usageCount}
        variant="round"
      />
    </Summary>
  );

  return (
    <StyledAccordion
      detailsClassName="accordion-details-container"
      color="secondary"
      detailsContent={detailsContent}
      summaryContent={summaryContent}
      variant="contained"
    />
  );
}
