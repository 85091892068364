import {
  OnOrderChange,
  ReorderableElement,
  ReorderableList,
} from '@monorepo/shared/componentsV2/dragAndDrop/ReorderableList';
import { FormTemplateFieldResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import { FieldTypeFactory } from './FieldTypeFactory';

interface ReorderableFormTemplateFieldsProps {
  openEditFieldModal: (fieldToEdit: FormTemplateFieldResponse) => void;
  sortedFields: FormTemplateFieldResponse[];
  updateFieldOrder: OnOrderChange;
}

export function ReorderableFormTemplateFieldsList({
  openEditFieldModal,
  sortedFields,
  updateFieldOrder,
}: ReorderableFormTemplateFieldsProps) {
  const orderedElements: ReorderableElement[] = useMemo(
    () =>
      sortedFields.map((field) => ({
        id: field.id,
        component: (
          <FieldTypeFactory
            field={field}
            openEditFieldModal={openEditFieldModal}
          />
        ),
      })),
    [sortedFields, openEditFieldModal],
  );

  return (
    orderedElements && (
      <ReorderableList
        orderedElements={orderedElements}
        onOrderChange={updateFieldOrder}
        uniqueDragListId="reorderableFormTemplateFields"
        listAriaLabel="Reorderable Form Template Fields"
      />
    )
  );
}
