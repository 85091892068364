import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import styled from 'styled-components';
import { VisuallyHidden } from './VisuallyHidden';

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray666};
  border-radius: 4px;

  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.gray333};
  }

  & input {
    padding: 6px 0;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray666};

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray666};
      opacity: 1;
    }
  }
`;

const SearchIconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  pointer-events: none;

  & svg {
    padding: 2px 0;
    fill: ${({ theme }) => theme.colors.gray666};
  }
`;

const Input = styled(InputBase)`
  padding-left: 2rem;
`;

type SearchProps = React.ComponentProps<typeof InputBase>;

export function Search(props: SearchProps) {
  const { placeholder } = props;
  return (
    <SearchContainer>
      <VisuallyHidden>
        <label htmlFor="search">{placeholder || 'Search'}</label>
      </VisuallyHidden>
      <SearchIconContainer>
        <SearchIcon />
      </SearchIconContainer>
      <Input
        inputProps={{ id: 'search' }}
        placeholder="Search"
        type="search"
        {...props}
      />
    </SearchContainer>
  );
}
