import { TextField } from '@monorepo/shared/componentsV2/fields/TextField';
import { Main } from '@monorepo/shared/componentsV2/Main';
import { focusOutline } from '@monorepo/shared/styles/focus';
import {
  contentPadding,
  fillHeightAndScrollable,
  page,
  verticalLayout,
} from '@monorepo/shared/styles/layout';
import styled from 'styled-components';

export const StyledMain = styled(Main)`
  ${page}
  ${verticalLayout}
`;

export const ColumnWrapper = styled.div`
  position: fixed;
  top: 72px;
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
`;

export const MainContent = styled.div`
  ${contentPadding}
  ${fillHeightAndScrollable}
  width: 70%;
  padding-bottom: 72px;
  margin-right: 4rem;
`;

export const Sidebar = styled.div`
  ${contentPadding}
  ${fillHeightAndScrollable}
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
  padding-bottom: 72px;
  color: ${({ theme }) => theme.colors.gray333};
  background-color: ${({ theme }) => theme.colors.grayeee};
`;

export const SectionButton = styled.button`
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.grayddd};
  border-style: solid;
  border-radius: 0.5rem;
  outline: none;

  &.active {
    background-color: #eaeaea;
  }

  &:focus-visible {
    ${focusOutline()}
  }
`;

export const ModalFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 50rem;
  margin: 0 0 0.1rem;
`;

export const ModalTextField = styled(TextField)`
  width: 50rem;
`;
