import { Button } from '@monorepo/shared/componentsV2/Button';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { BaseModal } from '@monorepo/shared/componentsV2/modals/BaseModal';
import { FormTemplateFieldResponse } from 'mapistry-shared';
import React from 'react';
import { Form } from 'react-final-form';
import { EditFieldForm } from './EditFieldForm';

export type EditFieldModalProps = {
  field: FormTemplateFieldResponse;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (field: FormTemplateFieldResponse) => Promise<void>;
};

export function EditFieldModal({
  field,
  isOpen,
  onClose,
  onSubmit,
}: EditFieldModalProps) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={field}
      subscription={{ invalid: true, pristine: true, submitting: true }}
    >
      {({ handleSubmit, pristine, invalid, submitting }) => (
        <BaseModal
          title="Configure field details"
          open={isOpen}
          onClose={onClose}
          maxWidth="md"
          buttons={
            <>
              <Button color="primary" onClick={onClose} type="button">
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={pristine || invalid || submitting}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
              >
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </>
          }
        >
          <ErrorBoundary>
            <EditFieldForm fieldType={field.type} />
          </ErrorBoundary>
        </BaseModal>
      )}
    </Form>
  );
}
