import { useToast } from '@monorepo/shared/contexts/ToastContext';
import {
  EdpViewResponse,
  QueryOperationType,
  QueryStep,
} from 'mapistry-shared';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormRenderProps } from 'react-final-form';
import { useLogQueryValidate } from '../hooks/useLogQueryValidate';
import { PotentiallyIncompleteQueryStep, ValidQueryStep } from '../types';

type QueryStepsFullContext = {
  clickHandler(
    callback?: (validatedSteps: ValidQueryStep[]) => void | Promise<void>,
  ): () => Promise<void>;
  getInProgressQuerySteps: () => PotentiallyIncompleteQueryStep[];
  updateInProgressQueryStep: (step: ValidQueryStep, stepIndex: number) => void;
  handleAddQueryStep(stepType: QueryOperationType): void;
  handleDeleteLastQueryStep(): void;
  logId: string;
  onQueryStepSubmit(step: QueryStep, index: number): Promise<void>;
  organizationId: string;
  projectId: string;
  queryStepsArePristine: boolean;
  lastQueryStepIsInvalid: boolean;
  setLastQueryStepIsInvalid(noSelectableOptions: boolean): void;
  registerHandleSubmitForStep(
    handleSubmit: FormRenderProps['handleSubmit'],
    stepIndex: number,
  ): void;
  setQueryStepsArePristine(areTheyPristine: boolean): void;
  validQuerySteps: ValidQueryStep[];
};

type QueryStepsContext = Omit<
  QueryStepsFullContext,
  | 'clickHandler'
  | 'onQueryStepSubmit'
  | 'registerHandleSubmitForStep'
  | 'lastQueryStepIsInvalid'
  | 'setLastQueryStepIsInvalid'
>;

type QueryStepsForm = Pick<
  QueryStepsFullContext,
  | 'onQueryStepSubmit'
  | 'registerHandleSubmitForStep'
  | 'setQueryStepsArePristine'
>;

type QueryStepsClickHandler = Pick<QueryStepsFullContext, 'clickHandler'>;

type LastQueryStepIsInvalid = Pick<
  QueryStepsFullContext,
  'lastQueryStepIsInvalid' | 'setLastQueryStepIsInvalid'
>;

export const QueryStepsContext = createContext<
  QueryStepsFullContext | undefined
>(undefined);

type QueryStepsProviderProps = {
  children: React.ReactNode;
  logId: string;
  organizationId: string;
  projectId: string;
  view?: EdpViewResponse;
};

export const QueryStepsProvider = ({
  children,
  logId,
  organizationId,
  projectId,
  view,
}: QueryStepsProviderProps) => {
  const { showUserFriendlyErrorToast } = useToast();
  // inProgressQuerySteps must be a ref so that the `onSubmit` handlers on each step's form can make updates
  //  to it within the same render cycle (a state variable will NOT update - this is why we
  //  also have clickHandlerCallback)
  const inProgressQueryStepsRef = useRef<PotentiallyIncompleteQueryStep[]>(
    view?.querySteps || [],
  );
  const getInProgressQuerySteps = useCallback(
    () => inProgressQueryStepsRef.current,
    [],
  );
  const [validQuerySteps, setValidQuerySteps] = useState<ValidQueryStep[]>(
    view?.querySteps || [],
  );
  const [submitFormHandlers, setSubmitFormHandlers] = useState<
    Record<number, FormRenderProps['handleSubmit'] | undefined>
  >({});
  const [queryStepsArePristine, setQueryStepsArePristine] = useState(true);
  const [lastQueryStepIsInvalid, setLastQueryStepIsInvalid] = useState(false);
  const afterValidateCallback =
    useRef<(validatedQuerySteps: ValidQueryStep[]) => void | Promise<void>>();
  const registerHandleSubmitForStep = useCallback(
    (
      handleSubmit: FormRenderProps['handleSubmit'] | undefined,
      stepIndex: number,
    ) => {
      if (!submitFormHandlers[stepIndex] || handleSubmit === undefined) {
        setSubmitFormHandlers({
          ...submitFormHandlers,
          [stepIndex]: handleSubmit,
        });
      }
    },
    [submitFormHandlers],
  );

  const updateInProgressQueryStep = (
    updatedStep: PotentiallyIncompleteQueryStep,
    stepIndex: number,
  ) => {
    const updatedSteps = [...inProgressQueryStepsRef.current];
    updatedSteps.splice(stepIndex, 1, updatedStep);
    inProgressQueryStepsRef.current = updatedSteps;
  };

  const handleSetQueryStepsArePristine = useCallback(
    (areTheyPristine: boolean) =>
      setQueryStepsArePristine(
        (wereTheyPristine: boolean) => wereTheyPristine && areTheyPristine,
      ),
    [],
  );

  const handleAddQueryStep = useCallback(
    (stepType: QueryOperationType) => {
      updateInProgressQueryStep(
        { operationType: stepType },
        getInProgressQuerySteps().length,
      );
      // since inProgressQuerySteps is a ref, react won't re-render this component unless something else changes
      // so we re-set submitFormHandlers to guarantee a re-render
      setSubmitFormHandlers((prev) => ({ ...prev }));
      setQueryStepsArePristine(false);
    },
    [getInProgressQuerySteps],
  );

  const handleDeleteLastQueryStep = useCallback(() => {
    const newInProgress = getInProgressQuerySteps().slice(0, -1);
    const newValid =
      newInProgress.length === validQuerySteps.length
        ? validQuerySteps
        : validQuerySteps.slice(0, -1);
    inProgressQueryStepsRef.current = newInProgress;
    setValidQuerySteps(newValid);
    registerHandleSubmitForStep(undefined, getInProgressQuerySteps().length); // clear handleSubmit for the deleted step
    setQueryStepsArePristine(false);
    setLastQueryStepIsInvalid(false);
  }, [getInProgressQuerySteps, registerHandleSubmitForStep, validQuerySteps]);

  const [validateQuerySteps] = useLogQueryValidate({
    config: { throwOnError: true },
  });

  const whichStepsHaveBeenValidatedRef = useRef<Record<number, boolean>>({});
  /**
   * All of the following occurs when the function returned from `clickHandler(callback)` is invoked
   *
   * If no query steps exist on the page (so nothing is under edit):
   *  1. Call the passed `callback`.
   *
   * If one or more query steps are being edited:
   *  1. Kicks off submission of the form for each step (i.e. call each of the submitFormHandlers), one after the
   *      other. This does any validation that has been setup with final form (e.g. required fields). If validation
   *      fails for any of the steps' forms, stop here (things should be setup so errors are shown inline).
   *  2. In each implementation of the query step's Form's `onSubmit` **MUST DO THE FOLLOWING**:
   *      a. Map the submitted form values to an updated QueryStep object.
   *      b. Call `onQueryStepSubmit` (defined below) with the updated QueryStep object. It will update `inProgressStepsRef`
            for each step, and at the last step, call the BE to perform validation. If this validation fails, stop
            (and show error message).
   *  3. `onQueryStepSubmit` updates `validQuerySteps` and calls the passed `callback`
   *
   * @param callback The process that the button clicked wishes to resume after the query steps are validated
   */
  const clickHandler = useCallback(
    (callback?: (validatedSteps: ValidQueryStep[]) => void | Promise<void>) =>
      async () => {
        // store the callback so we can call it later (see `onQueryStepSubmit`)
        afterValidateCallback.current = callback;

        whichStepsHaveBeenValidatedRef.current = {}; // reset this for this round of submitting the forms
        const isAnEarlierStepInvalid = (stepIndex: number) =>
          Object.keys(submitFormHandlers)
            .map((key) => Number(key))
            .some(
              (key) =>
                // the step has already been processed
                key < stepIndex &&
                // it had a form handler registered to it
                !!submitFormHandlers[key] &&
                // the onSubmit for that form wasn't called, meaning final-form validation failed
                !whichStepsHaveBeenValidatedRef.current[key],
            );

        // call the handleSubmit functions registered by each step
        await getInProgressQuerySteps().reduce(async (acc, _, stepIndex) => {
          await acc;
          // once we find an invalid step, quit calling the handlers (the passed `callback` won't get called!)
          if (isAnEarlierStepInvalid(stepIndex)) return;
          await submitFormHandlers[stepIndex]?.();
        }, Promise.resolve());
        // call `callback` directly if there aren't any steps in progress since nothing needs validated
        if (getInProgressQuerySteps().length === 0) {
          await callback?.(validQuerySteps);
        }
      },
    [getInProgressQuerySteps, validQuerySteps, submitFormHandlers],
  );

  /**
   * This should be called in the function passed to final-form's `Form` `onSubmit` prop. It updates
   * the in progress query steps and performs BE validation for the last step.
   */
  const onQueryStepSubmit = useCallback(
    async (editedQueryStep: QueryStep, stepIndex: number) => {
      // The query step has passed form validation
      updateInProgressQueryStep(editedQueryStep, stepIndex);
      whichStepsHaveBeenValidatedRef.current[stepIndex] = true;

      // each of the steps' forms is getting submitted in series, updating our
      //  `inProgressQueryStepsRef`
      // we only go to the server to validate the whole query on the last step
      if (stepIndex !== getInProgressQuerySteps().length - 1) return;

      try {
        const stepsToValidate = [
          ...getInProgressQuerySteps(),
        ] as ValidQueryStep[];
        await validateQuerySteps({
          logId,
          organizationId,
          projectId,
          query: { steps: stepsToValidate },
        });

        // The query step has passed validation
        // Promote the step being edited to validQuerySteps
        setValidQuerySteps(stepsToValidate);
        setQueryStepsArePristine(true);

        // Call the passed callback so that it can continue it's business
        await afterValidateCallback.current?.(stepsToValidate);
      } catch (err) {
        showUserFriendlyErrorToast(err, 'This query is invalid.');
      }
    },
    [
      getInProgressQuerySteps,
      logId,
      organizationId,
      projectId,
      validateQuerySteps,
      showUserFriendlyErrorToast,
    ],
  );

  const contextValue = useMemo<QueryStepsFullContext>(
    () => ({
      clickHandler,
      getInProgressQuerySteps,
      updateInProgressQueryStep,
      handleAddQueryStep,
      handleDeleteLastQueryStep,
      logId,
      onQueryStepSubmit,
      organizationId,
      projectId,
      queryStepsArePristine,
      registerHandleSubmitForStep,
      setQueryStepsArePristine: handleSetQueryStepsArePristine,
      validQuerySteps,
      lastQueryStepIsInvalid,
      setLastQueryStepIsInvalid,
    }),
    [
      clickHandler,
      getInProgressQuerySteps,
      handleAddQueryStep,
      handleDeleteLastQueryStep,
      logId,
      onQueryStepSubmit,
      organizationId,
      projectId,
      queryStepsArePristine,
      registerHandleSubmitForStep,
      handleSetQueryStepsArePristine,
      validQuerySteps,
      lastQueryStepIsInvalid,
    ],
  );

  return (
    <QueryStepsContext.Provider value={contextValue}>
      {children}
    </QueryStepsContext.Provider>
  );
};

export const useQuerySteps = (): QueryStepsContext => {
  const context = useContext(QueryStepsContext);

  if (context === undefined) {
    throw new Error('useQuerySteps must be used within a QueryStepProvider');
  }

  const {
    clickHandler,
    onQueryStepSubmit,
    registerHandleSubmitForStep,
    lastQueryStepIsInvalid,
    setLastQueryStepIsInvalid,
    ...rest
  } = context;

  return rest;
};

/**
 * To be used with final-form. Pass the `handleSubmit` function from the `Form`
 * render props to `registerHandleSubmitForStep` and call `onQueryStepSubmit` in the `onSubmit` function for the `Form`
 */
export const useQueryStepsForm = (): QueryStepsForm => {
  const context = useContext(QueryStepsContext);

  if (context === undefined) {
    throw new Error(
      'useQueryStepsForm must be used within a QueryStepProvider',
    );
  }

  const {
    onQueryStepSubmit,
    registerHandleSubmitForStep,
    setQueryStepsArePristine,
  } = context;

  return {
    onQueryStepSubmit,
    registerHandleSubmitForStep,
    setQueryStepsArePristine,
  };
};

/**
 * Pass a callback function to `clickHandler` that will be called once the query step
 * has been validated
 */
export const useQueryStepsClickHandler = (): QueryStepsClickHandler => {
  const context = useContext(QueryStepsContext);

  if (context === undefined) {
    throw new Error(
      'useQueryStepsClickHandler must be used within a QueryStepProvider',
    );
  }

  const { clickHandler } = context;

  return { clickHandler };
};

export const useLastQueryStepIsInvalid = (): LastQueryStepIsInvalid => {
  const context = useContext(QueryStepsContext);

  if (context === undefined) {
    throw new Error(
      'useLastQueryStepIsInvalid must be used within a QueryStepProvider',
    );
  }

  const { lastQueryStepIsInvalid, setLastQueryStepIsInvalid } = context;

  return { lastQueryStepIsInvalid, setLastQueryStepIsInvalid };
};
