import type { UpdateFormFieldsOrder } from '@monorepo/shared/apiClient/forms';
import { updateFormTemplateField } from '@monorepo/shared/apiClient/forms';
import { useReorderableItems } from '@monorepo/shared/componentsV2/dragAndDrop/useReorderableItems';
import { useToast } from '@monorepo/shared/contexts';
import { bodySmall } from '@monorepo/shared/styles/text';
import { FormTemplateFieldResponse } from 'mapistry-shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { EditFieldModal } from './EditFieldModal/EditFieldModal';
import { ReorderableFormTemplateFieldsList } from './ReorderableFormTemplateFieldsList';

type EditFormTemplateSectionFieldsProps = {
  fields?: FormTemplateFieldResponse[];
  formTemplateId: string;
  groupId: string;
  updateFieldOrderGroups: (
    fieldOrderGroup: UpdateFormFieldsOrder,
    orderedFields: FormTemplateFieldResponse[],
  ) => void;
};

const Instructions = styled('span')`
  ${bodySmall}
`;

export function EditFormTemplateSectionFields({
  fields,
  formTemplateId,
  groupId,
  updateFieldOrderGroups,
}: EditFormTemplateSectionFieldsProps) {
  const [currentlyOpenField, setCurrentlyOpenField] = useState<
    FormTemplateFieldResponse | undefined
  >();

  const [fieldListForGroup, setFieldListForGroup] = useState<
    [string, FormTemplateFieldResponse[]]
  >(['', []]);

  useEffect(() => {
    if (fields) setFieldListForGroup([groupId, fields]);
  }, [groupId, fields]);

  const fieldGroupId = fieldListForGroup[0];
  const fieldList = fieldListForGroup[1];

  const {
    orderedItems: orderedFields,
    updateItemOrder: updateFieldOrder,
    orderedItemIds: fieldOrder,
  } = useReorderableItems(fieldList);

  useEffect(() => {
    const fieldsAreNotUpdated = fields?.every(
      (element: unknown, index: number) => element === orderedFields[index],
    );

    if (fieldsAreNotUpdated || fieldGroupId !== groupId) return;

    updateFieldOrderGroups(
      { fieldGroupId: groupId, fieldOrder },
      orderedFields,
    );
  }, [
    fields,
    fieldOrder,
    groupId,
    orderedFields,
    updateFieldOrderGroups,
    fieldListForGroup,
    fieldGroupId,
  ]);

  const { organizationId } = useParams();
  const { showUserFriendlyErrorToast, success: showSuccessToast } = useToast();

  const closeFieldModal = useCallback(() => {
    setCurrentlyOpenField(undefined);
  }, [setCurrentlyOpenField]);

  const fieldSubmit = useCallback(
    async (updatedField: FormTemplateFieldResponse) => {
      try {
        if (organizationId) {
          await updateFormTemplateField({
            organizationId,
            id: formTemplateId,
            name: updatedField.name,
            groupId,
            fieldId: updatedField.id,
            isRequired: updatedField.isRequired,
            helpText: updatedField.helpText,
            nameSubtext: updatedField.nameSubtext,
          });
        }

        const updatedFields = fieldList.map((field) => {
          if (field.id === updatedField.id) {
            return updatedField;
          }
          return field;
        });

        setFieldListForGroup([groupId, updatedFields]);

        closeFieldModal();
        showSuccessToast('Field saved.');
      } catch (err) {
        showUserFriendlyErrorToast(err, `Unable to save field.`, {
          dontAutoHide: true,
        });
      }
    },
    [
      closeFieldModal,
      fieldList,
      formTemplateId,
      groupId,
      organizationId,
      showSuccessToast,
      showUserFriendlyErrorToast,
    ],
  );

  return (
    <div>
      <Instructions>Reorder or edit the fields in this section.</Instructions>
      {fields && (
        <ReorderableFormTemplateFieldsList
          openEditFieldModal={setCurrentlyOpenField}
          sortedFields={orderedFields}
          updateFieldOrder={updateFieldOrder}
        />
      )}
      {!!currentlyOpenField && (
        <EditFieldModal
          isOpen={!!currentlyOpenField}
          onClose={closeFieldModal}
          onSubmit={fieldSubmit}
          field={currentlyOpenField}
        />
      )}
    </div>
  );
}
