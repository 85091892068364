import { isRequired } from '@monorepo/shared/utils/validators';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { ModalFieldWrapper, ModalTextField } from '../../styled';
import { AlertText } from '../styled';

// this is rendered within FinalForm so the inputs are managed by it
export function EditNameField() {
  return (
    <>
      <ModalFieldWrapper>
        <ModalTextField
          label="Field Name"
          name="name"
          validate={isRequired}
          required
          dontSaveSpaceForErrorText
        />
      </ModalFieldWrapper>

      <FormSpy subscription={{ values: true, initialValues: true }}>
        {({ values, initialValues }) => {
          const shouldShowAlert =
            values.name !== initialValues.name &&
            values.name !== undefined &&
            values.name.trim() !== '';
          return (
            shouldShowAlert && (
              <AlertText>
                Changes will be applied to all past submissions; therefore,
                significant changes are not recommended as they may change the
                meaning of past form submissions.
              </AlertText>
            )
          );
        }}
      </FormSpy>
    </>
  );
}
