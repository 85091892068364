import { Link } from '@monorepo/shared/componentsV2/Link';
import { useSkipWidgetLink } from '@monorepo/shared/componentsV2/SkipWidgetLink';
import { Table, TableColumn } from '@monorepo/shared/componentsV2/Table';
import { useDynamicLinkOnSameProject } from '@monorepo/shared/hooks/navigation/useLinkOnSame/useLinkOnSameProject';
import { useHasProjectSettingsPermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { lookFor } from '@monorepo/shared/utils/lookFor';
import { DatasetChip } from '@monorepo/workflowDataSetup/src/components/shared/DatasetChip';
import { EdpViewRefResponse } from 'mapistry-shared';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { ViewsTableActions } from '../LogViewsTab/ViewsTableActions';

const StyledTable = styled(Table)`
  padding-bottom: 4rem;
` as typeof Table;

const StyledDatasetChip = styled(DatasetChip)`
  margin: 0.5rem 0;
`;

interface CrossLogViewsTableProps {
  filter: string;
  projectViews?: EdpViewRefResponse[];
}

function useGetCrossLogViewsTableColumns(canEditViews: boolean) {
  const { getLinkOnSameProject } = useDynamicLinkOnSameProject();

  const createViewNameColumnContent = useCallback(
    ({ id, name }) => (
      <Link to={getLinkOnSameProject(`/logs/views/${id}`)} color="grey">
        {name}
      </Link>
    ),
    [getLinkOnSameProject],
  );

  const createActionsColumnContent = useCallback(
    (view: EdpViewRefResponse) => (
      <ViewsTableActions
        viewId={view.id}
        viewName={view.name}
        viewOriginId={view.originId}
      />
    ),
    [],
  );

  const columns: TableColumn<EdpViewRefResponse>[] = useMemo(
    () => [
      {
        id: 'view-name',
        header: 'View Name',
        width: '35%',
        contents: createViewNameColumnContent,
      },
      {
        id: 'data-sources',
        header: 'Data source',
        width: '25%',
        // todo@workflows: give different origin types different icons
        contents: ({ originName }) => <StyledDatasetChip name={originName} />,
      },
      {
        id: 'description',
        header: 'Description',
        width: '20%',
        contents: 'description',
      },
      {
        id: 'limits',
        header: 'Limits',
        width: '10%',
        contents: 'limitQueryStepCount',
      },
      ...(canEditViews
        ? [
            {
              id: 'actions',
              header: 'Actions',
              contents: createActionsColumnContent,
              align: 'center',
              width: '10%',
            } as const,
          ]
        : []),
    ],
    [canEditViews, createActionsColumnContent, createViewNameColumnContent],
  );

  return columns;
}

export function CrossLogViewsTable({
  filter,
  projectViews,
}: CrossLogViewsTableProps) {
  const { projectId } = useParams();
  const canEditViews = useHasProjectSettingsPermissions(projectId);

  const filterProjectViewsBy = useCallback(
    (view) => lookFor(filter, view, ['name', 'originName']),
    [filter],
  );

  const columns = useGetCrossLogViewsTableColumns(canEditViews);

  const { SkipLink, SkipLinkTarget } = useSkipWidgetLink();

  return (
    <>
      <SkipLink linkText="Skip past Views table" />
      <StyledTable
        ariaLabel="All Views"
        columns={columns}
        rowFilter={filterProjectViewsBy}
        rows={projectViews || []}
      />
      <SkipLinkTarget />
    </>
  );
}
