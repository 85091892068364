import { List } from '@monorepo/shared/componentsV2/List';
import React from 'react';
import styled from 'styled-components';
import {
  FormulaTokenListItem,
  FormulaTokenListItemProps,
} from './FormulaTokenListItem';

const StyledList = styled(List)`
  width: 46.5rem;
  max-height: 20.5rem;
  overflow: auto;
  color: ${({ theme }) => theme.colors.gray666};
  border: solid 1px ${({ theme }) => theme.colors.grayddd};
  border-radius: 3px;

  & .MuiListSubheader-root {
    font-weight: 700;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.gray666};
    background-color: ${({ theme }) => theme.colors.grayeee};
  }
` as typeof List;

export type FormulaTokenListItemReactElement = React.ReactElement<
  FormulaTokenListItemProps,
  typeof FormulaTokenListItem
>;

export type FormulaTokenListItemProp = {
  header: string;
  items: FormulaTokenListItemReactElement[];
};

export type FormulaTokenListProps = {
  listItems: FormulaTokenListItemProp[];
};

export function FormulaTokenList({ listItems }: FormulaTokenListProps) {
  return <StyledList listItems={listItems} />;
}
