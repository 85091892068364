import { Loading } from '@monorepo/shared/components';
import { ErrorBoundary } from '@monorepo/shared/componentsV2/ErrorBoundary';
import { useOpenClose } from '@monorepo/shared/hooks/useOpenClose';
import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';
import { EditViewInfoModalProvider } from '../../contexts/EditViewInfoModalContext';
import { useProjectViews } from '../../hooks/useProjectViews';
import { LogsPageOutletContext } from '../LogsAndViewsPage';
import { ViewsToolbar } from '../LogViewsTab/ViewsToolbar';
import { CreateNewViewModal } from './CreateNewViewModal';
import { CrossLogViewsTable } from './CrossLogViewsTable';

const StyledLoading = styled(Loading)`
  margin-top: 5rem;
`;

export function CrossLogViewsPageContent() {
  const { organizationId, projectId } = useParams();
  const [
    createNewViewModalIsOpen,
    openCreateNewViewModal,
    closeCreateNewViewModal,
  ] = useOpenClose();
  const { setIsSearchVisible, filter } =
    useOutletContext<LogsPageOutletContext>();

  const { isLoading, projectViews } = useProjectViews({
    organizationId,
    projectId,
  });

  const hasViewsOnPage = !!(projectViews && projectViews.length > 0);
  setIsSearchVisible(hasViewsOnPage);

  if (isLoading) {
    return <StyledLoading />;
  }

  return (
    <ErrorBoundary>
      {createNewViewModalIsOpen && (
        <CreateNewViewModal
          closeModal={closeCreateNewViewModal}
          isOpen={createNewViewModalIsOpen}
        />
      )}
      <EditViewInfoModalProvider>
        <ViewsToolbar onCreateNewView={openCreateNewViewModal} />
        <CrossLogViewsTable filter={filter} projectViews={projectViews} />;
      </EditViewInfoModalProvider>
    </ErrorBoundary>
  );
}
