import { Api } from '@monorepo/shared/apiClient';
import { getQueryConfig } from '@monorepo/shared/utils/queryUtils';
import { FormTemplateResponse } from 'mapistry-shared';
import { queryCache, QueryConfig, useQuery } from 'react-query';
import { FetchFormTemplateParams } from '../components/EditFormTemplatePage/types';

type UseFormTemplateParams = Partial<FetchFormTemplateParams> & {
  config?: QueryConfig<FormTemplateResponse, Api.ErrorResponse>;
};

type KeyParams = FetchFormTemplateParams;

export const createKey = ({ slug, organizationId }: KeyParams) =>
  ['form-template', slug, organizationId] as const;

type Fetcher = Api.DataHookQueryFn<
  typeof createKey,
  typeof Api.fetchFormTemplate
>;

const fetcher: Fetcher = (_, slug, organizationId) =>
  Api.fetchFormTemplate({ slug, organizationId });

export const useFormTemplate = ({
  config: inputConfig,
  slug,
  organizationId,
}: UseFormTemplateParams) => {
  const isEnabled = !!organizationId && !!slug;
  const config = getQueryConfig(inputConfig, isEnabled);
  const key = isEnabled ? createKey({ slug, organizationId }) : undefined;
  const { data, ...queryInfo } = useQuery(key, fetcher, config);

  return {
    ...queryInfo,
    formTemplate: data,
  };
};

type QueryCache = ReturnType<typeof useFormTemplate>['formTemplate'];
type NonEmptyQueryCache = Exclude<QueryCache, undefined>;

export function setCache(keyParams: KeyParams, item: NonEmptyQueryCache): void {
  queryCache.setQueryData(createKey(keyParams), item);
}
