import React from 'react';
import { EditHelperTextField } from './EditHelperTextField';
import { EditIsRequiredField } from './EditIsRequiredField';
import { EditNameField } from './EditNameField';
import { EditSubtextField } from './EditSubtextField';

// this is rendered within FinalForm so the inputs are managed by it
export function EditGenericFieldForm() {
  return (
    <>
      <EditNameField />
      <EditIsRequiredField />
      <EditSubtextField />
      <EditHelperTextField />
    </>
  );
}
