import { Api } from '@monorepo/shared/apiClient';
import {
  invalidateViewCache,
  ViewCacheEvents,
} from '@monorepo/shared/cacheRegisters/data2/viewCacheRegister';
import { MutationConfig, useMutation } from 'react-query';
import { setCache as setViewCache } from './useView';

type useViewUpdateParams = {
  config?: MutationConfig<Api.UpdateViewResponse, Api.ErrorResponse>;
};

export const useViewUpdate = (params?: useViewUpdateParams) =>
  useMutation(Api.updateView, {
    ...params?.config,
    onSuccess: async (updatedView, mutationFuncParams) => {
      const { organizationId } = mutationFuncParams;

      // Set cache for that single view
      const keyParams = {
        organizationId,
        projectId: updatedView.projectId,
        viewId: updatedView.id,
      };
      setViewCache(keyParams, updatedView);

      // Invalidate any cache that includes info about views
      invalidateViewCache(ViewCacheEvents.UPDATE, keyParams);

      return params?.config?.onSuccess?.(updatedView, mutationFuncParams);
    },
  });
