import {
  OnOrderChange,
  ReorderableElement,
  ReorderableList,
} from '@monorepo/shared/componentsV2/dragAndDrop/ReorderableList';
import { FormTemplateFieldGroupResponse } from 'mapistry-shared';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormTemplateSection } from './FormTemplateSection';

const StyledFormTemplateSection = styled(FormTemplateSection)`
  margin: 0;

  /* so elements aren't see-through while being dragged over one another  */
  background-color: ${({ theme }) => theme.colors.white};
`;

interface ReorderableFormTemplateSectionsProps {
  sortedFormTemplateSections: FormTemplateFieldGroupResponse[];
  updateFormTemplateSectionOrder: OnOrderChange;
  setActiveGroup: (group: FormTemplateFieldGroupResponse) => void;
  activeGroupId?: string;
}

export function ReorderableFormTemplateSectionList({
  sortedFormTemplateSections,
  updateFormTemplateSectionOrder,
  setActiveGroup,
  activeGroupId,
}: ReorderableFormTemplateSectionsProps) {
  const orderedElements: ReorderableElement[] = useMemo(
    () =>
      sortedFormTemplateSections.map((section) => ({
        id: section.id.toString(),
        component: (
          <StyledFormTemplateSection
            name={section.name}
            clickHandler={() => {
              setActiveGroup(section);
            }}
            isDuplicable={section.isDuplicable}
            isActive={activeGroupId === section.id}
          />
        ),
      })),
    [sortedFormTemplateSections, setActiveGroup, activeGroupId],
  );

  return (
    <ReorderableList
      orderedElements={orderedElements}
      onOrderChange={updateFormTemplateSectionOrder}
      uniqueDragListId="reorderableFormTemplateSections"
      listAriaLabel="Reorderable Form Template Sections"
    />
  );
}
