import { Button } from '@monorepo/shared/componentsV2/Button';
import { ButtonsGroup } from '@monorepo/shared/componentsV2/ButtonsGroup';
import { A } from '@monorepo/shared/componentsV2/Link';
import { Toolbar } from '@monorepo/shared/componentsV2/Toolbar';
import { viewsProductGuideUrl } from '@monorepo/shared/externalLinks/productGuide';
import { useHasProjectSettingsPermissions } from '@monorepo/shared/hooks/permissions/useHasPermissions';
import { header4 } from '@monorepo/shared/styles/text';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from 'styled-components';

interface ViewsToolbarProps {
  onCreateNewView: () => void;
  title?: string;
}

const Title = styled.h2`
  ${header4}
`;

export function ViewsToolbar({ onCreateNewView, title }: ViewsToolbarProps) {
  const { projectId } = useParams();
  const canEditViews = useHasProjectSettingsPermissions(projectId);

  return (
    <Toolbar
      leftActions={title ? <Title>{title}</Title> : undefined}
      rightActions={
        <ButtonsGroup>
          <A color="grey" href={viewsProductGuideUrl} target="_blank">
            Learn about Views
          </A>
          {canEditViews && (
            <Button
              color="primary"
              onClick={onCreateNewView}
              variant="contained"
            >
              Create new View
            </Button>
          )}
        </ButtonsGroup>
      }
      size="small"
      variant="noDropShadow"
    />
  );
}
